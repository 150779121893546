<template>
	<div class="bardian" v-title="'局改焕新——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<NavBar></NavBar>
		<div class="box">
			<img
				class="bran"
				src="../image/decoration/part_header_pc.jpg"
				alt=""
			/>
		</div>
		<div class="box-mob">
            <img
				class="bran"
				src="../image/decoration/part_header_mob.jpg"
				alt=""
			/>
		</div>

		<div class="tabs f-x-c-c">
			<div 
                @click="cahngeTabs(1)" 
                @mouseenter="activeTab1=true" 
                @mouseleave="mouseleave(1)"  
                class="list-item f-x-c-c "
                :class="curTab==1 ? 'tab-active':''"
                >
				<transition
                    :duration="{enter:5000,leave:5000}"
                    mode="in-out"
					name="animate__animated animate__bounce"
					enter-active-class="animate__fadeInDown"
					leave-active-class="animate__fadeOutUp"
				>
					<img
						v-if="!activeTab1"
						class="item-img"
						src="../image/decoration/part_tab1.png"
						alt=""
					/>
				</transition>
                <transition
                    mode="out-in"
					name="animate__animated animate__bounce"
					enter-active-class="animate__fadeInUp"
					leave-active-class="animate__fadeOutDown"
				>
					<img
						v-if="activeTab1"
						class="item-img"
						src="../image/decoration/part_tab1_hover.png"
						alt=""
					/>
				</transition>
				
				<div class="title f-y-c-s">
					<span class="text1">厨房</span>
					<span class="text2">Kitchen</span>
				</div>
			</div>
			<div class="cus-line"></div>
			<div 
                @click="cahngeTabs(2)" 
                @mouseenter="activeTab2=true" 
                @mouseleave="mouseleave(2)"  
                class="list-item f-x-c-c"
                :class="curTab==2 ? 'tab-active':''"
                >
                <transition
					name="animate__animated animate__bounce"
					enter-active-class="animate__fadeInDown"
					leave-active-class="animate__fadeOutUp"
				>
					<img
						v-if="!activeTab2"
						class="item-img"
						src="../image/decoration/part_tab2.png"
						alt=""
					/>
				</transition>
                <transition
					name="animate__animated animate__bounce"
					enter-active-class="animate__fadeInUp"
					leave-active-class="animate__fadeOutDown"
				>
					<img
						v-if="activeTab2"
						class="item-img"
						src="../image/decoration/part_tab2_hover.png"
						alt=""
					/>
				</transition>
				
				<div class="title f-y-c-s">
					<span class="text1">卫生间</span>
					<span class="text2">Toilet</span>
				</div>
			</div>
			<div class="cus-line"></div>
			<div 
                @click="cahngeTabs(3)" 
                @mouseenter="activeTab3=true" 
                @mouseleave="mouseleave(3)"  
                :class="curTab==3 ? 'tab-active':''"
                class="list-item f-x-c-c"
                >
                <transition
					name="animate__animated animate__bounce"
					enter-active-class="animate__fadeInDown"
					leave-active-class="animate__fadeOutUp"
				>
					<img
						v-if="!activeTab3"
						class="item-img"
						src="../image/decoration/part_tab3.png"
						alt=""
					/>
				</transition>
                <transition
					name="animate__animated animate__bounce"
					enter-active-class="animate__fadeInUp"
					leave-active-class="animate__fadeOutDown"
				>
					<img
						v-if="activeTab3"
						class="item-img"
						src="../image/decoration/part_tab3_hover.png"
						alt=""
					/>
				</transition>
				<div class="title f-y-c-s">
					<span class="text1">墙面</span>
					<span class="text2">Metope</span>
				</div>
			</div>
		</div>


		<div v-if="curTab==1" class="content1 f-y-s-c">
			<div class="box1 f-y-s-c">
				<div class="header f-y-c-c">
					<span class="text1">极致工期 服务流程</span>
					<span class="text2">7个工作日完成改造·享受新厨房 </span>
				</div>
				<div class="box1-content f-x-b-c">
					<img
						class="img1"
						src="../image/decoration/part_tab1_box1.png"
						alt=""
					/>
				</div>
				<div class="form">
                    <VisitorForm2
                        title="极速快装报名"
                        desc="一站式局部焕新服务"
                        :showArea="false"
                        :showHouseType="false"
                        :showCity="false"
                        adUnitCode="qwer"
                    ></VisitorForm2>
				</div>
			</div>
			<div class="box2">
				<div class="header f-y-c-c">
					<span class="text1">定制厨房设计</span>
					<span class="text2">立体厨房操作+收纳系统</span>
				</div>
				<div class="box2-content f-y-c-c">
					<div class="tab-list f-y-c-c">
						<div class="list-item f-x-b-c">
							<img
								class="img2"
								src="../image/decoration/part_tab1_box2_1.jpg"
								alt=""
							/>
							<div class="info f-y-c-s">
								<div class="seq">01</div>
								<span class="text3">定制橱柜收纳</span>
								<p class="text4">
									根据厨房格局和顾客身高体型独家定制提高收纳利用率的同时，减少可能受伤的概览。
								</p>
							</div>
						</div>
						<div class="list-item rever f-x-b-c">
							<img
								class="img2"
								src="../image/decoration/part_tab1_box2_2.jpg"
								alt=""
							/>
							<div class="info f-y-c-s">
								<div class="seq">02</div>
								<span class="text3">智能改变生活</span>
								<p class="text4">
									各式智能设备一应俱全解放双手，享受加倍。
								</p>
							</div>
						</div>
						<div class="list-item f-x-b-c">
							<img
								class="img2"
								src="../image/decoration/part_tab1_box2_3.jpg"
								alt=""
							/>
							<div class="info f-y-c-s">
								<div class="seq">03</div>
								<span class="text3">人性化灯光设计</span>
								<p class="text4">
									集成灯、橱柜灯、操作灯、感应灯 驱散黑暗。
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box3">
				<div class="header f-y-c-c">
					<span class="text1">人性化细节设计</span>
					<span class="text2">厨房设计小细节大作用</span>
				</div>
				<div class="box3-content f-x-c-c">
					<div class="list f-x-b-c">
						<div class="list-item f-y-s-c">
							<img
								src="../image/decoration/part_box3_1.jpg"
								alt=""
								class="img"
							/>
							<span class="text3">定制不锈钢水槽</span>
							<div class="cus-line"></div>
						</div>
						<div class="list-item f-y-s-c">
							<img
								src="../image/decoration/part_box3_2.jpg"
								alt=""
								class="img"
							/>
							<span class="text3">可调节水龙头</span>
							<div class="cus-line"></div>
						</div>
						<div class="list-item f-y-s-c">
							<img
								src="../image/decoration/part_box3_3.jpg"
								alt=""
								class="img"
							/>
							<span class="text3">防油污防触电插座</span>
							<div class="cus-line"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="box4 f-x-e-c">
				<div class="box4-content f-y-c-s">
					<span class="text1">厨房焕新主材配置</span>
					<div class="list1 f-x-b-c">
						<div class="list-item1">墙地面</div>
						<div class="list-item1">厨房门</div>
						<div class="list-item1">厨房顶</div>
						<div class="list-item1">厨盆</div>
						<div class="list-item1">配件</div>
					</div>
					<div class="list2 f-x-b-c">
						<div class="list-item2">
							<div class="dian"></div>
							橱柜地柜
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							热水器柜
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							墙砖
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							燃气管
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							石英石包
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							集成灯
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							调味篮
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							煤气管
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							过门石
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							水槽
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							橱柜台面
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							百叶门
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							地砖
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							骑马抽
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							开关插座
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							集成吊灯
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							碗碟篮
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							橱柜吊柜
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							双面门套
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							水槽龙头
						</div>
						<div class="list-item2"></div>
					</div>
				</div>
			</div>
		</div>
        <div v-if="curTab==2" class="content2 f-y-s-c">
			<div class="box1 f-y-s-c">
				<div class="header f-y-c-c">
					<span class="text1">极致工期 服务流程</span>
					<span class="text2">14个工作日完成卫生间焕新 </span>
				</div>
				<div class="box1-content f-x-b-c">
					<img
						class="img1"
						src="../image/decoration/part_tab2_box1.png"
						alt=""
					/>
				</div>
				<div class="form">
					<VisitorForm2
                        title="极速快装报名"
                        desc="一站式局部焕新服务"
                        :showArea="false"
                        :showHouseType="false"
                        :showCity="false"
                        adUnitCode="qwer"
                    ></VisitorForm2>
				</div>
			</div>
			<div class="box2">
				<div class="header f-y-c-c">
					<span class="text1">美观大方的浴室柜</span>
					<span class="text2">全方面解决不易清洁的解决方案</span>
				</div>
				<div class="box2-content f-y-c-c">
					<div class="tab-list f-y-c-c">
						<div class="list-item f-x-c-c">
							<img
								class="img2"
								src="../image/decoration/part_tab2_box2_1.jpg"
								alt=""
							/>
							<div class="info f-y-c-s">
								<div class="seq">01</div>
								<span class="text3">美观实用</span>
								<p class="text4">
									大容量易收纳地脚抬高更美观
								</p>
							</div>
						</div>
						<div class="list-item rever f-x-c-c">
							<img
								class="img2"
								src="../image/decoration/part_tab2_box2_2.jpg"
								alt=""
							/>
							<div class="info f-y-c-s">
								<div class="seq">02</div>
								<span class="text3">好材好料</span>
								<p class="text4">
									防潮实木用料、防水、防蛀、防腐
								</p>
							</div>
						</div>
						<div class="list-item f-x-c-c">
							<img
								class="img2"
								src="../image/decoration/part_tab2_box2_3.jpg"
								alt=""
							/>
							<div class="info f-y-c-s">
								<div class="seq">03</div>
								<span class="text3">人性化灯光设计</span>
								<p class="text4">
									大功率、多功能浴霸，多效合一更方便
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box3">
				<div class="header f-y-c-c">
					<span class="text1">高颜值多方案选择</span>
					<span class="text2">空间美 + 潮流元 + 时尚搭</span>
				</div>
				<div class="box3-content f-x-c-c">
					<img class="img" src="../image/decoration/part_tab2_box3.jpg" alt="">
				</div>
			</div>
			<div class="box4 f-x-e-c">
				<div class="box4-content f-y-c-s">
					<span class="text1">卫生间焕新主材配置</span>
					<div class="list1 f-x-b-c">
						<div class="list-item1">墙地面</div>
						<div class="list-item1">卫生间门</div>
						<div class="list-item1">浴室柜</div>
						<div class="list-item1">坐便器</div>
						<div class="list-item1">配件</div>
					</div>
                         
					<div class="list2 f-x-b-c">
						<div class="list-item2">
							<div class="dian"></div>
							木门
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							集成吊灯
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							三角架
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							地砖
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							开关插座
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							马桶刷
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							过门石
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							坐便器
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							风暖浴霸
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							地漏
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							浴室柜
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							脸盆龙头
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							地砖
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							淋浴屏
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							花洒
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							单杆毛巾架
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							纸巾架
						</div>
						<div class="list-item2">
							<div class="dian"></div>
							墙砖
						</div>
					</div>
				</div>
			</div>
		</div>
        <div v-if="curTab==3" class="content3 f-y-s-c">
			<div class="box1 f-y-s-c">
				<div class="header f-y-c-c">
					<span class="text1">深度焕新 墙面刷新</span>
					<span class="text2">14个工作日完成墙面深度焕新 </span>
				</div>
				<div class="box1-content f-x-b-c">
					<img
						class="img1"
						src="../image/decoration/part_tab3_box1.jpg"
						alt=""
					/>
				</div>
				<div class="form">
					<VisitorForm2
                        title="极速快装报名"
                        desc="一站式局部焕新服务"
                        :showArea="false"
                        :showHouseType="false"
                        :showCity="false"
                        adUnitCode="qwer"
                    ></VisitorForm2>
				</div>
			</div>
			<div class="box2">
				<div class="header f-y-c-c">
					<span class="text1">墙面焕新 流程步聚</span>
					<span class="text2">一步到位+焕然一新</span>
				</div>
				<div class="box2-content ">
					<div class="tab-list">
						<div class="list-item f-y-c-c">
							<img
								class="img2"
								src="../image/decoration/part_tab3_box2_1.jpg"
								alt=""
							/>
							<div class="info f-y-c-c">
								<div class="seq">01</div>
                                <div class="cus-line"></div>
								<span class="text3">保护现场物品</span>
							</div>
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img2"
								src="../image/decoration/part_tab3_box2_2.jpg"
								alt=""
							/>
							<div class="info f-y-c-c">
								<div class="seq">02</div>
                                <div class="cus-line"></div>
								<span class="text3">打磨原墙乳胶漆</span>
							</div>
						</div>
                        <div class="list-item f-y-c-c">
							<img
								class="img2"
								src="../image/decoration/part_tab3_box2_3.jpg"
								alt=""
							/>
							<div class="info f-y-c-c">
								<div class="seq">03</div>
                                <div class="cus-line"></div>
								<span class="text3">修补原墙面</span>
							</div>
						</div>
                        <div class="list-item f-y-c-c">
							<img
								class="img2"
								src="../image/decoration/part_tab3_box2_4.jpg"
								alt=""
							/>
							<div class="info f-y-c-c">
								<div class="seq">04</div>
                                <div class="cus-line"></div>
								<span class="text3">底漆涂刷</span>
							</div>
						</div>
                        <div class="list-item f-y-c-c">
							<img
								class="img2"
								src="../image/decoration/part_tab3_box2_5.jpg"
								alt=""
							/>
							<div class="info f-y-c-c">
								<div class="seq">05</div>
                                <div class="cus-line"></div>
								<span class="text3">铲除大白</span>
							</div>
						</div>
                        <div class="list-item f-y-c-c">
							<img
								class="img2"
								src="../image/decoration/part_tab3_box2_6.jpg"
								alt=""
							/>
							<div class="info f-y-c-c">
								<div class="seq">06</div>
                                <div class="cus-line"></div>
								<span class="text3">界面剂涂刷</span>
							</div>
						</div>
                        <div class="list-item f-y-c-c">
							<img
								class="img2"
								src="../image/decoration/part_tab3_box2_7.jpg"
								alt=""
							/>
							<div class="info f-y-c-c">
								<div class="seq">07</div>
                                <div class="cus-line"></div>
								<span class="text3">石膏挂网找平</span>
							</div>
						</div>
                        <div class="list-item f-y-c-c">
							<img
								class="img2"
								src="../image/decoration/part_tab3_box2_8.jpg"
								alt=""
							/>
							<div class="info f-y-c-c">
								<div class="seq">08</div>
                                <div class="cus-line"></div>
								<span class="text3">两遍重复涂刷</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="box3">
				<div class="header f-y-c-c">
					<span class="text1">环保材料 无甲醛</span>
					<span class="text2">安心  +  放心  +  可靠</span>
				</div>
				<div class="box3-content f-x-b-c">
                    <div class="list-item f-y-c-c">
					    <img class="img" src="../image/decoration/part_tab3_box3_1.jpg" alt="">
                        <span class="text3">阿尔贝娜涂内墙乳胶漆</span>
                        <div class="cus-line"></div>
                    </div>
                    <div class="list-item f-y-c-c">
					    <img class="img" src="../image/decoration/part_tab3_box3_2.jpg" alt="">
                        <span class="text3">阿尔贝娜涂面漆+尊选底漆</span>
                        <div class="cus-line"></div>
                    </div>
                    <div class="list-item f-y-c-c">
					    <img class="img" src="../image/decoration/part_tab3_box3_3.jpg" alt="">
                        <span class="text3">水泥漆</span>
                        <div class="cus-line"></div>
                    </div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<Footer></Footer>

         <!-- 右浮窗和底部吸顶 -->
         <Floating></Floating>
         <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import Flow from '../components/Flow.vue'
import VisitorForm2 from '../components/VisitorForm2.vue'
import { postmobile } from '@/api/views/name.js'

export default {
	components: {
		NavBar,
		Footer,
		Flow,
		VisitorForm2,
	},

	data() {
		return {
            docState:'saved',
			isShow: false,
            activeTab1:false,
            activeTab2:false,
            activeTab3:false,
			curAddressVal: 0,
			curTab: 1,
			swiperOption1: {
				slidesPerView: 3.5,
				spaceBetween: '2%',
			},
			swiperOption2: {
				spaceBetween: '5%',
				effect: 'coverflow',
				grabCursor: true,
				centeredSlides: true,
				initialSlide: 1,
				// slidesPerView: 2.5,
				slidesPerView: 'auto',
				coverflowEffect: {
					rotate: 20,
					stretch: 0,
					depth: 0,
					modifier: 1,
					slideShadows: true,
				},
				pagination: {
					el: '.swiper2 .swiper-pagination',
				},
			},
			swiper2Index: 1,
		}
	},
	created() {},
	async mounted() {
        this.cahngeTabs(1)
	},
	methods: {
		
        mouseleave(index){
            if(index!=this.curTab) this['activeTab'+index] = false
        },
		cahngeTabs(index) {
			this.curTab = index
            this.activeTab1 = false
            this.activeTab2 = false
            this.activeTab3 = false
            this['activeTab'+index] = true
		},
		onSlideChange2() {
			this.swiper2Index = this.$refs.mySwiper2.$swiper.activeIndex
		},
		handelOption(data) {
			if (data == 'pre')
				this.$refs.mySwiper2.$swiper.slideTo(this.swiper2Index - 1)
			if (data == 'next')
				this.$refs.mySwiper2.$swiper.slideTo(this.swiper2Index + 1)
		},
	},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .bardian {
        overflow-x: hidden;
		.box {
            display: none;
			
		}
        .box-mob{
            width: 100%;
			height: 200px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .tabs {
			width: 100%;
			margin-top: 25px;
			.cus-line {
				width: 1px;
				height: 20px;
				background: #e5e5e5;
				border-radius: 1px;
                margin: 0 20px;
			}
            .list-item:hover{
                .title{
                    .text1,.text2{
                        color: #753CBE;
                    }
                }
            }
            .tab-active{
                .title{
                    .text1,.text2{
                        color: #753CBE !important;
                    }
                }
            }
			.list-item {
                overflow: hidden;
                cursor: pointer;
				width: 80px;
                position: relative;

				.item-img {
                    position: absolute;
                    left: 0;
					width: 30px;
					height: 30px;
				}

				.title {
                    margin-left: 30px;
					.text1 {
						
						font-weight: bold;
						font-size: 12px;
						color: #120523;
					}
					.text2 {
						
						font-weight: 400;
						font-size: 10px;
						color: #120523;
					}
				}
			}
		}
		.content1 {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;

			.box1 {
				width: 100%;
				box-sizing: border-box;
				margin-top: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box1-content {
					margin-top: 25px;
					padding: 0 20px;
					width: 100%;
					height: 180px;
					box-sizing: border-box;
					.img1 {
						width: 100%;
						height: 100%;
                        // object-fit: cover;
					}
				}
				.form {
					margin-top: 20px;
					padding: 0 25px;
					width: 100%;
					box-sizing: border-box;
				}
			}
			.box2 {
				margin-top: 50px;
				width: 100%;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box2-content {
					margin-top: 20px;
					width: 100%;
					.rever {
						flex-direction: row-reverse;
						.info {
							padding: 0 30px 0 50px !important;
                            margin-right: -100px !important;
                            margin-left: 0 !important;
						}
					}
                    .tab-list{
                        width: 100%;
                    }
					.list-item {
						width: 100%;
						height: 200px;
						margin-bottom: 30px;
						.img2 {
							width: 70%;
							height: 100%;
                            object-fit: cover;
						}
						.info {
							// flex: 1;
							padding: 0 50px 0 30px;
                            margin-left: -100px;
                            width: 250px;
                            height: 140px;
                            box-sizing: border-box;
                            padding: 0 18px;
                            background:#fff;
                            opacity: 0.9;
							.seq {
								width: 25px;
								height: 30px;
								font-family: DIN;
								font-weight: bold;
								font-size: 20px;
								color: #753cbe;
								line-height: 30px;
								border-bottom: 1px solid #753cbe;
							}
							.text3 {
								margin-top: 15px;
								
								font-weight: bold;
								font-size: 20px;
								color: #120523;
								line-height: 20px;
							}
							.text4 {
								margin-top: 15px;
								
								font-weight: 400;
								font-size: 12px;
								color: #444444;
								line-height: 16px;
							}
						}
					}
				}
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
					margin-top: 20px;
					padding: 0 10px;
					box-sizing: border-box;
					.list {
						width: 100%;
						.list-item {
							width: 110px;
							height: 160px;
							background: #ffffff;
							box-shadow: 0px 3px 24px 0px rgba(4, 0, 0, 0.08);
							border-radius: 5px;
							.img {
								width: 100%;
								height: 120px;
                                object-fit: cover;
								border-radius: 5px 5px 0px 0px;
							}
							.text3 {
								margin-top: 5px;
								
								font-weight: 500;
								font-size: 12px;
								color: #111111;
								line-height: 18px;
							}
							.cus-line {
								width: 20px;
								height: 2px;
								background: #753cbe;
								margin-top: 5px;
							}
						}
					}
				}
			}
			.box4 {
				width: 100%;
				margin-top: 50px;
				background:no-repeat  url('../image/decoration/part_tab1_box4_bg.jpg');
				background-size: 100% auto;
                background-position:top;
				// background-repeat: no-repeat;
                justify-content: center;
                align-items: flex-end;
				.box4-content {
                    margin-top: 250px;
					width: 90%;
					// height: 400px;
					padding: 20px 20px 20px 30px;
					box-sizing: border-box;
					border-radius: 5px;
					background: #fff;
					.text1 {
						
						font-weight: bold;
						font-size: 26px;
						color: #1c0835;
						line-height: 26px;
					}
					.list1 {
						width: 100%;
						.list-item1 {
							// width: 140px;
                            padding: 0 5px;
							height: 20px;
							border: 1px solid #753cbe;
							display: flex;
							justify-content: center;
							align-items: center;
							
							font-weight: 400;
							font-size: 14px;
							color: #120523;
							margin-top: 10px;
							box-sizing: border;
						}
					}
					.list2 {
						width: 100%;
						flex-wrap: wrap;
						margin-top: 10px;
						.list-item2 {
							
							font-weight: 400;
							font-size: 14px;
							color: #282422;
							width: 33%;
							display: flex;
							justify-items: left;
							align-items: center;
							margin-bottom: 8px;
							.dian {
								width: 4px;
								height: 4px;
								border-radius: 50%;
								margin-right: 3px;
								background: #000;
							}
						}
					}
				}
			}
		}
        .content2 {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;
            .box1 {
				width: 100%;
				box-sizing: border-box;
				margin-top: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box1-content {
					margin-top: 25px;
					padding: 0 20px;
					width: 100%;
					height: 180px;
					box-sizing: border-box;
					.img1 {
						width: 100%;
						height: 100%;
                        // object-fit: cover;
					}
				}
				.form {
					margin-top: 20px;
					padding: 0 25px;
					width: 100%;
					box-sizing: border-box;
				}
			}
			.box2 {
				margin-top: 50px;
				width: 100%;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box2-content {
					margin-top: 20px;
					width: 100%;
					.rever {
						flex-direction: row-reverse;
						.info {
							padding: 0 30px 0 50px !important;
                            margin-right: -100px !important;
                            margin-left: 0 !important;
						}
					}
                    .tab-list{
                        width: 100%;
                    }
					.list-item {
						width: 100%;
						height: 200px;
						margin-bottom: 30px;
						.img2 {
							width: 70%;
							height: 100%;
                            object-fit: cover;
						}
						.info {
							// flex: 1;
							padding: 0 50px 0 30px;
                            margin-left: -100px;
                            width: 250px;
                            height: 140px;
                            box-sizing: border-box;
                            padding: 0 18px;
                            background:#fff;
                            opacity: 0.9;
							.seq {
								width: 25px;
								height: 30px;
								font-family: DIN;
								font-weight: bold;
								font-size: 20px;
								color: #753cbe;
								line-height: 30px;
								border-bottom: 1px solid #753cbe;
							}
							.text3 {
								margin-top: 15px;
								
								font-weight: bold;
								font-size: 20px;
								color: #120523;
								line-height: 20px;
							}
							.text4 {
								margin-top: 15px;
								
								font-weight: 400;
								font-size: 12px;
								color: #444444;
								line-height: 16px;
							}
						}
					}
				}
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 20px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
                    height: 240px;
					margin-top: 20px;
					.img {
                        width:100%;
                        height: 100%;
                        object-fit: cover;
                    }
				}
			}
            .box4 {
				width: 100%;
				margin-top: 50px;
				background:no-repeat  url('../image/decoration/part_tab2_box4_bg.jpg');
				background-size: 100% auto;
				// background-repeat: no-repeat;
                justify-content: center;
                align-items: flex-end;

				.box4-content {
                    margin-top: 250px;
					width: 90%;
					// height: 400px;
					padding: 20px 20px 20px 30px;
					box-sizing: border-box;
					border-radius: 5px;
					background: #fff;
					.text1 {
						
						font-weight: bold;
						font-size: 26px;
						color: #1c0835;
						line-height: 26px;
					}
					.list1 {
						width: 100%;
						.list-item1 {
							// width: 140px;
                            padding: 0 5px;
							height: 20px;
							border: 1px solid #753cbe;
							display: flex;
							justify-content: center;
							align-items: center;
							
							font-weight: 400;
							font-size: 14px;
							color: #120523;
							margin-top: 10px;
							box-sizing: border;
						}
					}
					.list2 {
						width: 100%;
						flex-wrap: wrap;
						margin-top: 10px;
						.list-item2 {
							
							font-weight: 400;
							font-size: 14px;
							color: #282422;
							width: 33%;
							display: flex;
							justify-items: left;
							align-items: center;
							margin-bottom: 8px;
							.dian {
								width: 4px;
								height: 4px;
								border-radius: 50%;
								margin-right: 3px;
								background: #000;
							}
						}
					}
				}
			}
		}
        .content3 {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;
            .box1 {
				width: 100%;
				box-sizing: border-box;
				margin-top: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box1-content {
					margin-top: 25px;
					padding: 0 50px;
					width: 100%;
					height: 240px;
					box-sizing: border-box;
					.img1 {
						width: 100%;
						height: 100%;
                        object-fit: cover;
					}
				}
				.form {
					margin-top: 20px;
					padding: 0 25px;
					width: 100%;
					box-sizing: border-box;
				}
			}
			.box2 {
				margin-top: 50px;
				width: 100%;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box2-content {
					margin-top: 20px;
					width: 100%;
                    .tab-list{
                        width: 100%;
                        // flex-wrap: wrap;
                        display: grid;
                        grid-template-columns: repeat(3,1fr);
                        gap: 0px 10px;
                        .list-item {
                            width: 100%;
                            margin-bottom: 20px;
                            .img2{
                                width: 100%;
                                height: 100px;
                                object-fit: cover;
                            }
                            .info{
                                margin-top:-30px ;
                                width: 110px;
                                height: 60px;
                                background: #FFFFFF;
                                box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.16);
                                border-radius: 3px;
                                .seq{
                                    font-family: DIN;
                                    font-weight: bold;
                                    font-size: 18px;
                                    color: #753CBE;
                                    line-height: 18px;
                                }
                                .cus-line{
                                    margin-top: 5px;
                                    width: 30px;
                                    height: 2px;
                                    background: #753CBE;
                                }
                                .text3{
                                    
                                    font-weight: bold;
                                    font-size: 14px;
                                    color: #120523;
                                    line-height: 14px;
                                    margin-top: 8px;
                                }
                            }
                        }
                    }
					
				}
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 30px;
                margin-bottom: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
					margin-top: 10px;
                    padding: 0 12px;
                    box-sizing: border-box;
                    flex-flow: column;
                    .list-item{
                        width: 100%;
                        height: 220px;
                        padding-bottom: 10px;
                        box-sizing: border-box;
                        .img {
                            width:100%;
                            height: 180px;
                            object-fit: cover;
                        }
                        .text3{
                            text-align: center;
                            
                            font-weight: bold;
                            font-size: 12px;
                            color: #120523;
                            line-height: 12px;
                            margin-top:5px;
                        }
                        .cus-line{
                            width: 20px;
                            height: 2px;
                            background: #753CBE;
                            margin-top: 5px;
                        }
                    }
					
				}
			}
			
		}
    }
}
@media screen and (min-width: 900px) {
	.bardian {
		.box {
			width: 100%;
			height: 780px;
			// margin-top: 96px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
        .box-mob{
            display: none;
        }
		
		.tabs {
			width: 100%;
			margin-top: 50px;
			.cus-line {
				width: 1px;
				height: 40px;
				background: #e5e5e5;
				border-radius: 1px;
                margin: 0 40px;
			}
            .list-item:hover{
                .title{
                    .text1,.text2{
                        color: #753CBE;
                    }
                }
            }
            .tab-active{
                .title{
                    .text1,.text2{
                        color: #753CBE !important;
                    }
                }
            }
			.list-item {
                cursor: pointer;
				width: 150px;
                position: relative;
                overflow: hidden;
				.item-img {
                    position: absolute;
                    left: 0;
					width: 60px;
					height: 60px;
				}

				.title {
                    margin-left: 75px;
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						
						font-weight: 400;
						font-size: 20px;
						color: #120523;
					}
				}
			}
		}
		.content1 {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;

			.box1 {
				width: 100%;
				box-sizing: border-box;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
					.text3 {
						margin-top: 20px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box1-content {
					margin-top: 50px;
					padding: 0 180px;
					width: 100%;
					height: 780px;
					box-sizing: border-box;
					.img1 {
						width: 100%;
						height: 100%;
                        object-fit: cover;
					}
				}
				.form {
					margin-top: 84px;
					padding: 0 180px;
					width: 100%;
					box-sizing: border-box;
				}
			}
			.box2 {
				margin-top: 130px;
				width: 100%;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
					.text3 {
						margin-top: 20px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box2-content {
					margin-top: 43px;
					width: 100%;
					.rever {
						flex-direction: row-reverse;
						.info {
							padding: 0 70px 0 180px !important;
						}
					}
					.list-item {
						width: 100%;
						height: 860px;
						margin-bottom: 72px;
						.img2 {
							width: 1095px;
							height: 860px;
						}
						.info {
							flex: 1;
							padding: 0 180px 0 70px;
							.seq {
								width: 100px;
								height: 100px;
								font-family: DIN;
								font-weight: bold;
								font-size: 80px;
								color: #753cbe;
								line-height: 80px;
								border-bottom: 5px solid #753cbe;
							}
							.text3 {
								margin-top: 60px;
								
								font-weight: bold;
								font-size: 60px;
								color: #120523;
								line-height: 60px;
							}
							.text4 {
								margin-top: 48px;
								
								font-weight: 400;
								font-size: 36px;
								color: #444444;
								line-height: 60px;
							}
						}
					}
				}
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
					margin-top: 40px;
					padding: 0 180px;
					box-sizing: border-box;
					.list {
						width: 100%;
						.list-item {
							width: 500px;
							height: 700px;
							background: #ffffff;
							box-shadow: 0px 3px 24px 0px rgba(4, 0, 0, 0.08);
							border-radius: 10px;
							.img {
								width: 500px;
								height: 540px;
								border-radius: 10px 10px 0px 0px;
							}
							.text3 {
								margin-top: 44px;
								
								font-weight: 500;
								font-size: 44px;
								color: #111111;
								line-height: 44px;
							}
							.cus-line {
								width: 80px;
								height: 5px;
								background: #753cbe;
								margin-top: 20px;
							}
						}
					}
				}
			}
			.box4 {
				width: 100%;
				margin-top: 130px;
				background: url('../image/decoration/part_tab1_box4_bg.jpg');
				background-size: 60% 100%;
				background-repeat: no-repeat;
				.box4-content {
					width: 900px;
					height: 980px;
					padding: 0 70px 0 80px;
					box-sizing: border-box;
					border-radius: 100px 0 0 100px;
					background: #fff;
					.text1 {
						
						font-weight: bold;
						font-size: 76px;
						color: #1c0835;
						line-height: 80px;
					}
					.list1 {
						width: 100%;
						.list-item1 {
							// width: 140px;
                            padding: 0 15px;
							height: 70px;
							border: 2px solid #753cbe;
							display: flex;
							justify-content: center;
							align-items: center;
							
							font-weight: 400;
							font-size: 36px;
							color: #120523;
							margin-top: 40px;
							box-sizing: border;
						}
					}
					.list2 {
						width: 100%;
						flex-wrap: wrap;
						margin-top: 40px;
						.list-item2 {
							
							font-weight: 400;
							font-size: 36px;
							color: #282422;
							width: 33%;
							display: flex;
							justify-items: left;
							align-items: center;
							margin-bottom: 20px;
							.dian {
								width: 8px;
								height: 8px;
								border-radius: 50%;
								margin-right: 5px;
								background: #000;
							}
						}
					}
				}
			}
			.box5 {
				background: #fff;
				width: 100%;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box5-content {
					width: 100%;
					height: 1156px;
					margin-top: 40px;
					padding: 0 180px;
					box-sizing: border-box;
					background: url(../image/decoration/bardian_box5_bg.jpg);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					.info {
						width: 1058px;
						height: 880px;
						background: #ffffff;
						box-shadow: 0px 12px 20px 0px rgba(4, 0, 0, 0.11);
						border-radius: 15px;
						padding: 40px;
						box-sizing: border-box;
						position: relative;
						margin-right: 50px;
						.top {
							width: 100%;
							.info-img {
								width: 440px;
								height: 516px;
								margin-right: 40px;
								object-fit: cover;
							}
							.info-desc {
								flex: 1;
								padding-top: 74px;
								height: 100%;
								box-sizing: border-box;
								.header {
									.text1 {
										
										font-weight: bold;
										font-size: 60px;
										color: #120523;
										line-height: 60px;
										margin-right: 30px;
									}
									.text2 {
										
										font-weight: 400;
										font-size: 30px;
										color: #120523;
										line-height: 30px;
									}
								}
								.text3 {
									
									font-weight: 400;
									font-size: 30px;
									color: #120523;
									line-height: 30px;
									margin-top: 35px;
								}
								.text4 {
									
									font-weight: 400;
									font-size: 30px;
									color: #120523;
									line-height: 48px;
									margin-top: 36px;
									flex: 1;
								}
								.btn {
									width: 280px;
									height: 100px;
									background: #753cbe;
									margin-top: 70px;
									
									font-weight: 400;
									font-size: 36px;
									color: #ffffff;
									cursor: pointer;
								}
							}
						}
						.bottom {
							margin-top: 30px;
							width: 100%;
							.header {
								width: 100%;
								.title {
									
									font-weight: 500;
									font-size: 28px;
									color: #666666;
									margin-right: 10px;
								}
								.line {
									flex: 1;
									height: 1px;
									background: #e5e5e5;
								}
							}
							.carousel {
								margin-top: 32px;
								height: 200px;
								width: 100%;
								.swiper {
									height: 100%;
								}
								.img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}
						}
						.jiao {
							position: absolute;
							width: 0;
							height: 0;
							border: 25px solid;
							right: -50px;
							top: 100px;
							border-color: transparent transparent transparent
								#ffffff;

							// position: absolute;
							// right:-50px;
							// top: 100px;
							// width: 100px;
							// height: 100px;
							// background: red;
							// clip-path: polygon(0 0,0 100%,100% 100%);
							// box-shadow: -20px 12px 20px 0px rgba(25, 0, 255, 0.91);
						}
					}
					.list {
						width: 480px;
						height: 880px;
						display: grid;
						grid-template-columns: auto auto;
						grid-template-rows: repeat(3, 1fr);
						gap: 20px 20px;
						.list-item {
							cursor: pointer;
							width: 230px;
							height: 280px;
							border: 3px solid #fff;
							box-sizing: border-box;
							position: relative;
							padding: 2px;
							background: #fff;
							.img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
							.right {
								position: absolute;
								width: 0;
								height: 0;
								top: -50px;
								right: -50px;
								border: 50px solid;
								// border-color: #FD8B2F transparent transparent transparent ;
								border-color: transparent transparent #fd8b2f
									transparent;
								transform: rotate(45deg);
							}
							.left {
								position: absolute;
								width: 0;
								height: 0;
								bottom: -50px;
								left: -50px;
								border: 50px solid;
								// border-color: #FD8B2F transparent transparent transparent ;
								border-color: transparent #fd8b2f transparent
									transparent;
								transform: rotate(-45deg);
							}
						}
						.des-active {
							border: 3px solid #fd8b2f !important;
						}
					}
				}
			}
			.box6 {
				background: #fff;
				width: 100%;
				margin-top: 130px;
				margin-bottom: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.carousel {
					width: 100%;
					height: 900px;
					margin-top: 40px;
					box-sizing: border-box;
					// padding-top: 50px;
					// padding-bottom: 50px;
					.swiper2 {
						height: 100%;
						height: 700px;

						.swiper-slide {
							border-radius: 8px;
							overflow: hidden;
							// height: 580px;
							// width: 958px;

							display: flex;
							justify-content: center;
							align-items: center;
							width: 958px;
							height: 700px;
							text-align: center;
							font-weight: bold;
							/deep/.img {
								height: 580px;
								width: 958px;
								border-radius: 8px 8px 0 0;
							}
							/deep/.text3 {
								width: 958px;
								height: 120px;
								background: #753cbe;
								border-radius: 0 0 8px 8px;

								
								font-weight: bold;
								font-size: 48px;
								color: #ffffff;
							}
						}
					}
					.options {
						margin-top: 60px;
						.btn {
							width: 125px;
							height: 125px;
							border-radius: 50%;
							border: 1px solid #e5e5e5;
							cursor: pointer;
							.img1 {
								font-size: 60px;
								font-weight: bold;
							}
						}
						.btn:hover {
							border: 1px solid #753cbe;
							.img1 {
								color: #753cbe;
							}
						}
					}
				}
			}
		}
        .content2 {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;

			.box1 {
				width: 100%;
				box-sizing: border-box;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
					.text3 {
						margin-top: 20px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box1-content {
					margin-top: 50px;
					padding: 0 180px;
					width: 100%;
					height: 780px;
					box-sizing: border-box;
					.img1 {
						width: 100%;
						height: 100%;
                        object-fit: cover;
					}
				}
				.form {
					margin-top: 84px;
					padding: 0 180px;
					width: 100%;
					box-sizing: border-box;
				}
			}
			.box2 {
				margin-top: 130px;
				width: 100%;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
					.text3 {
						margin-top: 20px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box2-content {
					margin-top: 43px;
					width: 100%;
					.rever {
						flex-direction: row-reverse;
						.info {
							padding: 0 70px 0 180px !important;
						}
					}
					.list-item {
						width: 100%;
						height: 860px;
						margin-bottom: 72px;
						.img2 {
							width: 1095px;
							height: 860px;
						}
						.info {
							flex: 1;
							padding: 0 180px 0 70px;
							.seq {
								width: 100px;
								height: 100px;
								font-family: DIN;
								font-weight: bold;
								font-size: 80px;
								color: #753cbe;
								line-height: 80px;
								border-bottom: 5px solid #753cbe;
							}
							.text3 {
								margin-top: 60px;
								
								font-weight: bold;
								font-size: 60px;
								color: #120523;
								line-height: 60px;
							}
							.text4 {
								margin-top: 48px;
								
								font-weight: 400;
								font-size: 36px;
								color: #444444;
								line-height: 60px;
							}
						}
					}
				}
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 90px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
                    height: 1200px;
					margin-top: 50px;
					.img {
                        width:100%;
                        height: 100%;
                    }
				}
			}
			.box4 {
				width: 100%;
				margin-top: 130px;
				background: url('../image/decoration/part_tab2_box4_bg.jpg');
				background-size: 60% 100%;
				background-repeat: no-repeat;
				.box4-content {
					width: 900px;
					height: 980px;
					padding: 0 70px 0 80px;
					box-sizing: border-box;
					border-radius: 100px 0 0 100px;
					background: #fff;
					.text1 {
						
						font-weight: bold;
						font-size: 76px;
						color: #1c0835;
						line-height: 80px;
					}
					.list1 {
						width: 100%;
						.list-item1 {
                            padding: 0 15px;
							// width: 140px;
							height: 70px;
							border: 2px solid #753cbe;
							display: flex;
							justify-content: center;
							align-items: center;
							
							font-weight: 400;
							font-size: 36px;
							color: #120523;
							margin-top: 40px;
							box-sizing: border;
						}
					}
					.list2 {
						width: 100%;
						flex-wrap: wrap;
						margin-top: 40px;
						.list-item2 {
							
							font-weight: 400;
							font-size: 36px;
							color: #282422;
							width: 33%;
							display: flex;
							justify-items: left;
							align-items: center;
							margin-bottom: 20px;
							.dian {
								width: 8px;
								height: 8px;
								border-radius: 50%;
								margin-right: 5px;
								background: #000;
							}
						}
					}
				}
			}
		}
        .content3 {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;

			.box1 {
				width: 100%;
				box-sizing: border-box;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
					.text3 {
						margin-top: 20px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box1-content {
					margin-top: 50px;
					padding: 0 550px;
					width: 100%;
					height: 720px;
					box-sizing: border-box;
					.img1 {
						width: 100%;
						height: 100%;
                        object-fit: cover;
					}
				}
				.form {
					margin-top: 84px;
					padding: 0 180px;
					width: 100%;
					box-sizing: border-box;
				}
			}
			.box2 {
				margin-top: 130px;
				width: 100%;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
					.text3 {
						margin-top: 20px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box2-content {
					margin-top: 43px;
					width: 100%;
                    
                    .tab-list{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        .list-item {
                            width: 450px;
                            margin-bottom: 50px;
                            .img2{
                                width: 100%;
                                height: 328px;
                                object-fit: cover;
                            }
                            .info{
                                margin-top:-90px ;
                                width: 394px;
                                height: 180px;
                                background: #FFFFFF;
                                box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.16);
                                border-radius: 5px;
                                .seq{
                                    font-family: DIN;
                                    font-weight: bold;
                                    font-size: 54px;
                                    color: #753CBE;
                                    line-height: 54px;
                                }
                                .cus-line{
                                    margin-top: 15px;
                                    width: 70px;
                                    height: 5px;
                                    background: #753CBE;
                                }
                                .text3{
                                    
                                    font-weight: bold;
                                    font-size: 44px;
                                    color: #120523;
                                    line-height: 44px;
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
					
				}
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 120px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
					margin-top: 50px;
                    margin-bottom: 100px;
                    padding: 0 180px;
                    box-sizing: border-box;
                    .list-item{
                        width: 500px;
                        .img {
                            width:100%;
                            height: 360px;
                        }
                        .text3{
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #120523;
                            line-height: 36px;
                            margin-top:23px;
                            
                        }
                        .cus-line{
                            width: 70px;
                            height: 5px;
                            background: #753CBE;
                            margin-top: 24px;
                        }
                    }
					
				}
			}
			
		}
	}
}
</style>
